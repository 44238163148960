import React from 'react'
import PropTypes from 'prop-types'
import css from 'styled-jsx/css'
import { remCalc } from '../util/math'

const MainContent = ({ html }) => (
  <>
    <div
      className="content"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: html }}
    />
    <style jsx>{styles}</style>
  </>
)

MainContent.propTypes = {
  html: PropTypes.string.isRequired
}

const styles = css`
.content {
  max-width: ${remCalc(800)};
  margin: 0 auto;
  :global(h1, h2, h3, h4, ul, ol, p:not(.block-img)) {  
    max-width: ${remCalc(600)};
    margin-left: auto;
    margin-right: auto;
  }
}
`

export default MainContent
