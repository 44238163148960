import PropTypes from 'prop-types'
import React from 'react'
import { remCalc } from '../util/math'
import sizes from '../config/sizes'

const Container = ({ children, noGutters }) => (
  <div className="container">
    {children}
    <style jsx>
      {`
      .container {
        max-width: ${remCalc(sizes.xl)};
        margin: 0 auto;
        padding: 0 ${noGutters ? 0 : '1rem'};
      }  
    `}
    </style>
  </div>
)

Container.propTypes = {
  children: PropTypes.node.isRequired,
  noGutters: PropTypes.bool
}

Container.defaultProps = {
  noGutters: undefined
}

export default Container
