import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'


import Layout from '../components/layout'
import SEO from '../components/seo'
import Intro from '../components/intro'
import MainContent from '../components/mainContent'

const About = () => {
  const res = useStaticQuery(graphql`
    query {
      prismicPage(uid: { eq: "about" }) {
        data {
          title {
            text
          }
          intro {
            html
            text
          }
          content {
            html
          }
        }
      }
    }
  `)

  const { data } = res.prismicPage

  return (
    <Layout>
      <SEO title={data.title.text} description={data.intro.text} />
      <Intro>
        <h1>{data.title.text}</h1>
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: data.intro.html }}
        />
      </Intro>
      <MainContent html={data.content.html} />
    </Layout>
  )
}

export default About
