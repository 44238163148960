import React from 'react'
import theme from '../config/theme'

const Footer = () => (
  <footer className="footer">
    &copy;
    {` ${new Date().getFullYear()} Kristoffer Eriksson. All rights reserved.`}
    <style jsx>
      {`
    .footer {
      padding: 4rem 0;
      text-align: center;
      font-size: 0.8rem;
      color: ${theme.color.gray};
    }
  `}
    </style>
  </footer>
)

export default Footer
