/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import 'normalize.css'
import Container from './container'
import Header from './header'
import Footer from './footer'
import sizes from '../config/sizes'
import theme from '../config/theme'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>

      <Header siteTitle={data.site.siteMetadata.title} />
      <Container className="container">
        <main>{children}</main>
      </Container>
      <Footer />

      <style jsx global>
        {`
        html {
          font-family: -apple-system, BlinkMacSystemFont, “Roboto”, “Droid Sans”, “Helvetica Neue”, Helvetica, Arial, sans-serif;
          font-weight: 300;
          color: ${theme.color.textColor};
          font-size: 18px;

          @media only screen and (min-width: ${sizes.lg}px) {
              font-size: 20px;
          }

          @media only screen and (min-width: ${sizes.xl}px) {
              font-size: 22px;
          }

          @media only screen and (min-width: ${sizes.xxl}px) {
              font-size: 24px;
          }
        }
        *::selection {
            background: PapayaWhip; /* WebKit/Blink Browsers */
        } 
        div {box-sizing: border-box;}
        h1, h2, h3 {
          color: ${theme.color.black}
        }

        img {
          max-width: 100%;
          display: block;
        }

        .block-img img {
          margin: 4rem auto;
        }

        strong, b {
          font-weight: 700;
        }

        p, ul, ol, blockquote {
          line-height: 1.4em;
        }

        p {
          a {
            font-weight: 700;
            color: ${theme.color.black};
            transition: all 0.2s;
            &:hover {
              background: PapayaWhip;
            }
          }
        }
      `}
      </style>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
