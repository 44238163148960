export default {
  color: {
    textColor: '#666',
    black: '#000',
    darkestGray: '#222',
    darkerGray: '#444',
    darkGray: '#666',
    gray: '#888',
    lightGray: '#aaa'
  }
}
