import React from 'react'
import PropTypes from 'prop-types'
import css from 'styled-jsx/css'
import { remCalc } from '../util/math'
import theme from '../config/theme'

const Intro = ({ children }) => (
  <div className="intro">
    {children}
    <style jsx>{styles}</style>
  </div>
)

Intro.propTypes = {
  children: PropTypes.node.isRequired
}

const styles = css`
.intro {
  margin: 4rem auto;
  text-align: center;
  max-width: ${remCalc(600)};
  color: ${theme.color.lightGray};
  :global(p) {
    font-size: 1.3rem;
  }
}
`

export default Intro
