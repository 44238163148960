import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import { globalHistory } from '@reach/router'
import logo from '../images/k-logo.svg'
import Container from './container'
import theme from '../config/theme'
import sizes from '../config/sizes'

const Header = ({ siteTitle }) => {
  const isWorkPage = globalHistory.location.pathname.match(/^\/$|^\/project\//gi)
  return (
    <>
      <header className="site-header">
        <Container>
          <Link className="logo" to="/">
            <img src={logo} alt={siteTitle} />
          </Link>
          <nav className="main-nav">
            <ul>
              <li><Link className={isWorkPage && 'active'} to="/">Work</Link></li>
              <li><Link activeClassName="active" to="/about">About</Link></li>
            </ul>
          </nav>
          <div className="currently">
            {'Currently working at '}
            <a href="https://www.above.se/">Above</a>
          </div>
        </Container>
      </header>
      <div className="header-spacer" />
      <style jsx>
        {`
      .site-header {
        padding: 0.8rem 0;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        /*background: rgba(255, 255, 255, 0.95);*/
        background: #fff;
        z-index: 1000;

        @media only screen and (min-width: ${sizes.xxl}px) {
          padding: 0.5rem 0;
        }

        :global(.container) {
          display: flex;
          flex-direction: row;
        }

        :global(.logo) {
          align-self: center;
        }

        :global(.logo img) {
          display: block;
          max-height: 1.2rem;
          margin-right: 1rem;
          img {
            max-height: 100%;
          }
        }
      }

      .currently, .main-nav :global(a) {
        font-size: 14px;
        line-height: 2rem;
      } 
      

      .currently {
        margin-left: auto;
        color: ${theme.color.gray};
        display: none;
        @media only screen and (min-width: ${sizes.md}px) {
          display: block;
        }
        a {
          color: ${theme.color.black};
        }
      }

      .main-nav {
        margin-left: auto;
        @media only screen and (min-width: ${sizes.md}px) {
          margin-left: 0;
        }
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          li {
            padding: 0;
            display: inline-block;
          }
        }

        :global(a) {
          display: block;
          padding: 0 1rem;
          color: ${theme.color.gray};
          text-decoration: none;
          font-weight: 400;
          text-transform: uppercase;

          &:hover {
            color: ${theme.color.black};
          }
        }

        :global(a.active) {
          color: ${theme.color.black};
        }
      }

      .header-spacer {
        height: 4rem;
        @media only screen and (min-width: ${sizes.xxl}px) {
          height: 3rem;
        }
      }
      
    `}
      </style>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ''
}

export default Header
